import React from  'react';
import djery from '../../images/WddingPictures/djeri.JPG'
import Sectiontitle from '../section-title'
import './style.css'



const Getting = () => {
    return(
        <div className="gta-area section-padding">
            {/* <Sectiontitle section={'Бъдете Там'}/> */}
            <Sectiontitle />
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading col-md-12 col-sm-6">
                                <h4>Цветя</h4>
                                <div className="gta-content">
                                    <p>За цветя не мислете, а в нашата кауза се включете!
                                        Нека зарадваме нашите космати приятели от фондация "Шаро" с много бисквитки, храна, играчки, одеала, легълца и всичко, от което се нуждаят. </p>
                                </div>
                                <div className="gta-img">
                                    <img src={djery} alt=""/>
                                </div>
                            </div>
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Транспорт</h4>
                                <div className="gta-content-1">
                                    <p>Тръване от София: 16:30, Храм паметник Александър Невски</p>
                                    <p>Връщане: Автобусът ще изпълни два курса: в 1:00ч. и в 3:00ч.</p>
                                </div>
                                <div className="gta-img">
                                {/* <img src={gta2} alt=""/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Getting;
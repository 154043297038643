
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css'
import img1 from "../../images/WddingPictures/image1.jpg"

const Welcome = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (

    <div  style={{backgroundPosition:" 58% 0%"}}  className={`welcome-area ${props.welcome}`}>
     {/* <img src={img1} alt="img" /> */}
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="welcome-content">
                        {/* <h2>Welcome to our big day</h2> */}
                        <h2>Добре дошли на нашия голям ден</h2>

                        <p>Ние, Димитър и Цветомира, имаме радостта и удоволствието да Ви поканим на сватбеното ни тържество, което ще се състои на 9-ти август 2024г.
                            Нека да отпразнуваме заедно момента, в който ще си кажем “Да” и ще обещаем пред света и пред нас самите да бъдем завинаги заедно.
                        </p>
                        <div className="btn"><AnchorLink href='#rsvp'>Форма</AnchorLink></div>
                        {/* <Button className="btn" onClick={toggle}>Локация</Button> */}
                        {/* <Modal isOpen={modal} toggle={toggle} className={className}> */}
                            {/* <ModalHeader toggle={toggle}>Location</ModalHeader> */}
                            {/* <ModalBody> */}
                                <div className="location-map">
                                    <link href="https://www.google.com/maps/place/Villa+Ekaterina/@42.552299,23.676255,14z/data=!4m9!3m8!1s0x40aa76d1a297f56d:0xb91ebfd3efa7f568!5m2!4m1!1i2!8m2!3d42.5520199!4d23.6806176!16s%2Fg%2F1hdzjx1cz?hl=en-US&entry=ttu" />
                                </div>
                            {/* </ModalBody> */}
                        {/* </Modal> */}
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
}

export default Welcome;
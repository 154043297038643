import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
// import strory1 from '../../images/story/img-1.jpg'
// import strory2 from '../../images/story/img-2.jpg'
import strory1 from '../../images/OurWeddingPics/IMG_0163.jpg'
import strory2 from '../../images/OurWeddingPics/snimka-2.jpeg'
// import strory3 from '../../images/story/img-3.jpg'
// import strory4 from '../../images/story/img-4.jpg'
import strory3 from '../../images/OurWeddingPics/predlojenie.jpg'
import strory4 from '../../images/imagesBubaNIkol/image6.jpeg'
import './style.css'


const Story = () =>{
    return(
        <div id="story" className="story-area section-padding">
            <Sectiontitle section={'Нашата история'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Нашето запознанството</h3>
                                {/* <h3>Нашата първа среща</h3> */}

                                <p>
                                Пътищата ни се сливат през 2018 за първи път в офиса, там където Митко се налага да преследва Цвети, а тя така настойчиво го подлага на тестове, за да се увери в сериозните му намерения. След няколко успешни теста, той е допуснат завинаги в сърцето ѝ. Щастието започва да се крие в само три букви-ние! 
                                </p>
                                {/* <div className="story-button">
                                    <Link className="theme-btn" to='/'>Read More</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Предложението за брак</h3>
                                <p>
                                 На 13.03.2023, на един красив занзибарски плаж, по залез слънце Митко иска ръката на Цвети. Речта му е дълга около 4 минути, но тя си спомня много малка част от нея от вълнение. Тя казва най-категоричното “ ДА” в своя живот..”Положили сме едни чудесни основи на нашата къща на любовта , а сега е време да градим останалите етажи”.-казва той.
                                </p>
                                <div className="story-button">
                                   {/* <Link className="theme-btn" to='/'>Read More</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory3} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img-3">
                                <img src={strory2} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Нашият годеж</h3>
                                <p>
                                 На 01.10.2023, дата неслучайно избрана, защото тогава навършваме 5 години от нашето запознанство, ние решихме да положим нашите подписи и официално да се слеем в едно семейство. Сега ви каним да отпразнувате с нас нашата любов!                                      </p>
                                <div className="story-button">
                                   {/* <Link className="theme-btn" to='/'>Read More</Link> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Нашия годеж</h3>
                                <span className="date"> 2021г</span>
                                <p>“Един топъл юнски ден, докато бяхме на почивка, се случи нещо, което преобърна живота ни. Стояхме на местност, от която се виждаше сливането на морето с брега. Височината беше голяма и паниката ме обвзе. Точно по залез слънце, обръщайки се към него, видях усмивката му, докато той беше коленичил с пръстен в ръка. Бях толкова изненадана, че за момент не можах да осъзная какво се случва. Тогава той каза думите, които винаги тайно съм желала да чуя. Благодарение на тях успях да забравя страха от височината, всякаш всичко останало около нас изчезна и бяхме само аз и той. Емоциите нахлуха като ураган, но дори и за момент не се поколебах, и казах “ДА”. Този специален момент ще запомня завинаги.” - Тя</p>
                                <div className="story-button">
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory4} alt=""/>
                            </div>
                        </div> */}
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;
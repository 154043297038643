import React from 'react';
import './style.css'

const Footer = (props) => {
    return(
        <div className='wrapper'>
        <div  style={{backgroundPosition:" 50% 20%"}} className={`site-footer ${props.footer}`}>
            <div className="container">
                <div className="row">
                    <div className="text">
                        {/* <h2>Lubomir & Nikoleta</h2> */}
                        <h2>Димитър & Цветомира</h2>
                        {/* <p>Благодарим</p> */}
                    </div>
                </div>
            </div> 
        </div>
        </div>
    )
}

export default Footer;
import React from  'react';

// components
import Couple from '../../components/couple';
import HeroMain from '../../components/HeroMain';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import People from '../../components/people';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Getting from '../../components/getting';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Footer from '../../components/footer'
import img1 from '../../images/WddingPictures/profile3.jpg'
import img2 from '../../images/WddingPictures/profile4.jpg'



const HomeMain = () => {
    return(
       <div>
           <Navbar/>
           <HeroMain/>
           {/* <SimpleSlider /> */}
           <Saveday saveday={'s2'}/>
           <Couple couple={'s2'} coupleimg1={img1} coupleimg2={img2}/>
           <Welcome welcome={'s2'}/>
           <Story/>
           <People guest={'guest-style'}/>
           <Location/>
           <Gallery/>
           <Rsvp/>
           <Getting/>
           <Gift/>
           <Footer footer={'s2'}/>
       </div>
    )
}

export default HomeMain;
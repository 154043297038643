import React, { Component } from "react";
import Slider from "react-slick";
import {Animated} from "react-animated-css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import img1 from '../../images/imagesBubaNIkol/image3.jpeg'
import img1 from '../../images/WddingPictures/gushi.jpg'
// import img2 from '../../images/imagesBubaNIkol/image0.jpeg'
import img2 from '../../images/WddingPictures/image4.jpg'
import img4 from '../../images/WddingPictures/profile6.jpg'
import img5 from '../../images/WddingPictures/image4-mobile.jpg'
import img4Resize from '../../images/WddingPictures/profile6-mobile.jpg'

import { useWindowDimension } from "./windows-dimension";





 const HeroMain = () => {

  const [width, height] = useWindowDimension();


  // const width = window.innerWidth;

      const settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:2500,
        fade:true
      };
      return (
        <Slider {...settings}>
       
          <div style={{backgroundPosition:" 81% 191%"}} className="items2">
          {/* <div className='wrap-img-2'>   */}
           {/* </div>  */}
          {width < 542 ? (
          <img src={img5} className="img2" alt='weddingimg' />
          ): (
          <img src={img2} className="img2" alt='weddingimg' />
          )}
            <div className="container">
                <div className="container-1"> 
                      <div className="slide-content">
                          <div className="slide-subtitle">
                              {/* <h4>WERE GETTING MARRIED</h4> */}
                              <h4>НИЕ ЩЕ СЕ ЖЕНИМ!</h4>

                          </div>
                          <div className="slide-title">
                              {/* <h2>Save Our Date</h2> */}
                              <h2>Бъдете наши гости</h2>
                          </div>
                          <div className="slide-text">
                              <p>09 Август 2024</p>
                          </div>
                          <Animated>
                            <div className="animated-circle"></div>
                          </Animated>
                      </div>
                    </div>
                </div>
          </div>
          <div style={{backgroundPosition:" 50% 20%"}} className="items3">
          {/* <div className='wrap-img-2'>   */}
           {width < 542 ? (
          <img src={img4Resize} className="img2" alt='weddingimg' />
          ): (
          <img src={img4} className="img2" alt='weddingimg' />
          )}
          {/* </div> */}
            <div className="container">
                <div className="container-2"> 
                      <div className="slide-content">
                          <div className="slide-subtitle">
                              {/* <h4>WERE GETTING MARRIED</h4> */}
                              <h4>НИЕ ЩЕ СЕ ЖЕНИМ!</h4>

                          </div>
                          <div className="slide-title">
                              {/* <h2>Save Our Date</h2> */}
                              <h2>Бъдете наши гости</h2>
                          </div>
                          <div className="slide-text">
                              <p>09 Август 2024</p>
                          </div>
                          <Animated>
                            <div className="animated-circle"></div>
                          </Animated>
                      </div>
                    </div>
                </div>
          </div>
        </Slider>
      );
    }

export default HeroMain;